/* src/styles/fonts.css */


/* Open Sans Light */

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/OpenSans-Light.ttf') format('truetype');
}


/* Open Sans Regular */

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/OpenSans-Regular.ttf') format('truetype');
}


/* Open Sans Semi-bold */

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/OpenSans-SemiBold.ttf') format('truetype');
}


/* Open Sans Bold */

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/OpenSans-Bold.ttf') format('truetype');
}


/* Roboto Light */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Roboto-Light.ttf') format('truetype');
}


/* Roboto Regular */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Roboto-Regular.ttf') format('truetype');
}


/* Roboto Bold */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/Roboto-Bold.ttf') format('truetype');
}